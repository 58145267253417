<template>
  <el-dialog v-model="state.showAddUserDialog" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="state.ruleForm"
      :rules="rules"
      label-width="52px"
      class="demo-ruleForm"
      :size="formSize"
      status-icon>
      <el-form-item label="账号" prop="username" v-if="!state.disable">
        <el-input v-model="state.ruleForm.username" placeholder="请输入账号" />
      </el-form-item>

      <el-form-item label="密码" v-if="state.disable">
        <el-input type="password" v-model="state.ruleForm.password" placeholder="不输入密码原密码不会更改" />
      </el-form-item>

      <el-form-item label="密码" prop="password" v-else>
        <el-input type="password" v-model="state.ruleForm.password" placeholder="请输入密码" />
      </el-form-item>

      <el-form-item label="部门" prop="department">
        <el-input v-model="state.ruleForm.department"  placeholder="请输入部门" />
      </el-form-item>

      <el-form-item label="姓名" prop="name">
        <el-input v-model="state.ruleForm.name"  placeholder="请输入姓名" />
      </el-form-item>

      <el-form-item label="职位" prop="job">
        <el-input v-model="state.ruleForm.job"  placeholder="请输入职位" />
      </el-form-item>

      <el-form-item label="手机" prop="phone_no">
        <el-input maxlength="11" v-model="state.ruleForm.phone_no"  placeholder="请输入手机号" />
      </el-form-item>

      <el-form-item label="抄送" prop="copy_for">
        <el-radio-group v-model="state.ruleForm.copy_for">
          <el-radio :label="0" >不可抄送</el-radio>
          <el-radio :label="1" >可以抄送</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button v-if="!state.disable" @click="resetForm(ruleFormRef)">重新录入</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          {{ state.disable ? '确认修改' : '确认录入' }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { leaderCreate, leaderUpdate } from '@/api'
const formSize = ref('default')
const ruleFormRef = ref(null)

const state = reactive({
  showAddUserDialog: false,
  ruleForm: {
    username: '',
    password: '',
    department: '',
    name: '',
    job: '',
    phone_no: '',
    copy_for: 0 // 是否有抄送权限 0:无 1:有
  },
  disable: false
})

const rules = {
  username: [
    { required: true, message: '请输入账号', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 23, message: '密码为6-23位', trigger: 'blur' }
  ],
  department: [
    { required: true, message: '请输入部门', trigger: 'blur' }
  ],
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
  ],
  job: [
    { required: true, message: '请输入职位', trigger: 'blur' }
  ],
  phone_no: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
  ],
  copy_for: [
    { required: true, message: '请选择抄送权限', trigger: 'change' }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      if (state.disable) {
        leaderUpdateApi()
      } else {
        leaderCreateApi()
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

function show (item) {
  state.showAddUserDialog = true
  if (item) {
    state.disable = true
    state.ruleForm.id = item.id
    state.ruleForm.department = item.department
    state.ruleForm.name = item.name
    state.ruleForm.job = item.job
    state.ruleForm.phone_no = item.phone_no
    state.ruleForm.copy_for = item.copy_for
  } else {
    state.disable = false
    state.ruleForm = {
      username: '',
      password: '',
      department: '',
      name: '',
      job: '',
      phone_no: '',
      copy_for: 0
    }
  }
}

// 签批领导创建接口
function leaderCreateApi () {
  leaderCreate(state.ruleForm).then(res => {
    ElMessage({
      type: 'success',
      message: '创建成功'
    })
    state.showAddUserDialog = false
    emit('finished')
  })
}

// 签批领导修改接口
function leaderUpdateApi () {
  const params = {
    id: state.ruleForm.id,
    department: state.ruleForm.department,
    name: state.ruleForm.name,
    job: state.ruleForm.job,
    phone_no: state.ruleForm.phone_no,
    copy_for: state.ruleForm.copy_for
  }
  if (state.ruleForm.password) {
    params.password = state.ruleForm.password
  }
  leaderUpdate(params).then(res => {
    ElMessage({
      type: 'success',
      message: '修改成功'
    })
    state.showAddUserDialog = false
    emit('finished')
  })
}

const emit = defineEmits([
  'finished'
])

defineExpose({
  show
})
</script>

<style scoped>
.footer_text {
  margin-right: 20px;
}
</style>
