<template>

  <el-row type="flex" style="margin: 15px 0;">
    <el-col :span="12">
      <!-- <el-input v-model="state.search" style="width: 200px;" placeholder="请输入用户名" /> -->
    </el-col>
    <el-col :span="12" align="right">
      <el-button type="primary" icon="Plus" @click="handleAddUser">录入</el-button>
    </el-col>
  </el-row>

  <el-table stripe border style="width: 100%"
    :data="filterTableData" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="用户" prop="name">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><User /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.name }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="登录账号" prop="username" />

    <el-table-column label="部门" prop="department" />
    <el-table-column label="职位" prop="job" />

    <el-table-column label="手机" width="150px">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><Iphone /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.phone_no }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="创建时间">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="190">
      <template #header>
        <el-input v-model="state.search" size="small" placeholder="请输入用户名" />
      </template>

      <template #default="scope">
        <el-button
          size="small"
          @click="handleEdit(scope.row)">
          修改
        </el-button>

        <el-button
          size="small"
          type="danger"
          icon="Delete"
          @click="handleDelete(scope.row)">
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <AddUser ref="addUserRef" @finished="finished"></AddUser>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { leaderList, leaderUpdate } from '@/api'
import AddUser from './components/addUser.vue'
const addUserRef = ref(null)

// 录入
function handleAddUser () {
  addUserRef.value.show()
}

// 表格数据
const state = reactive({
  loading: false,
  tableData: []
})

const filterTableData = computed(() =>
  state.tableData.filter((data) =>
    !state.search || data.name.toLowerCase().includes(state.search.toLowerCase())
  )
)

// 编辑
function handleEdit (item) {
  console.log('编辑', item)
  addUserRef.value.show(item)
}

// 删除
function handleDelete (item) {
  console.log('删除', item)
  ElMessageBox.confirm(
    '确认删除吗?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    leaderUpdate({ id: item.id, is_del: 1 }).then(res => {
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      leaderListApi()
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除'
    })
  })
}

function finished () {
  leaderListApi()
}

leaderListApi()
// 获取签批领导列表接口
function leaderListApi () {
  state.loading = true
  leaderList().then(res => {
    state.tableData = res
    state.loading = false
  })
}
</script>

<style lang="scss" scoped>

</style>
